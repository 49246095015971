import React from "react"

import SEO from "../../components/SEO"
// import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import clever_strategies_bk from "../../../static/images/2/help/image_adcreation.svg"
import clever_strategies_bk_MOB from "../../../static/images/2/help/image_adcreation_MOB.svg"
import home_header_bk from "../../../static/images/2/home/header_bk.svg"

import AOS from 'aos';


import seoImage from '../../../static/images/2/webinar/dec-2020/facebook.jpg';
import NewsletterSignUp from "../../components/MC/NewsletterSignUp"

const Dec = () => {

    React.useEffect(() => {
        AOS.init();
    },[]);
    
    return (
      <>
        <SEO
          title="brillea - helping purpose-driven startups launch & grow"
          description="Want more customers using Facebook & Instagram ads. Join our Webinar & learn how to run your own  campaigns"
          banner={seoImage}
        />


<div className={"section section_header section_home"}>
          <div className="section_header__bk_image">
            <img src={home_header_bk} alt="brillea smarter digital marketing" />
          </div>
          <Container>
            <Row>
              <Col
                className={"homeHeaderContent"}
                md={{ size: 7 }}
                sm={{ size: 12 }}
              >
                <h1 className="mb-3">Want more customers using Facebook &amp; Instagram ads?</h1>
                <h2 className="color_purple_light mb-4">Watch our webinar &amp; learn the basics of running your own campaigns!</h2>
                <NewsletterSignUp
                    Groups={{
                      MC_GROUP_EXPERTS: false,
                      MC_GROUP_USERS: false,
                      MC_GROUP_NEWS: true,
                    }}
                    location={"/webinar/dec-2020-preview/"}
                    btnText={"Watch Now"}
                  />
              </Col>
              <Col
                className="homeHeaderImage_wrapper pt-3"
                md={{ size: 5, offset: 0 }}
                sm={{ size: 12 }}
              >
              <img
                className="hidden-sm"
                src={clever_strategies_bk}
                alt="clever digital marketing strategies"
              />
              <img
                className="display-sm"
                src={clever_strategies_bk_MOB}
                alt="clever digital marketing strategies"
              />
              </Col>
            </Row>
          </Container>
        </div>

      

      </>
    )
}
export default Dec
